<template>
  <section class="pc:px-[10%] mobile:px-4 bg-osn-yellow-00 flex flex-col justify-center">
    <h2 class="py-8 text-[28px] font-bold text-center">{{ $t('article.space_article') }}</h2>
    <ul class="flex justify-center flex-wrap mobile:flex-col">
      <li v-for="article in blogArticle"
          class="mx-[10px] mb-10 pc:w-[22%] pc:max-w-[360px] mobile:border-b-2">
        <NuxtLink :to="'/blog/'+article.static_url">
<!--          <img class="rounded-xl aspect-[360/200]"
               :src="picUrl+article.photo_url"
               alt="">-->
          <NuxtImg class="rounded-xl w-full"
                   format="webp"
                   quality="80"
                   loading="lazy"
                   width="360"
                   height="200"
                   sizes="100vw lg:360px"
                   fit="cover"
                   placeholder
                   :src="picUrl+article.photo_url"
                   :alt="article.subject"/>
          <h3 class="text-[18px] my-5 font-bold text-center">{{ article.subject }}</h3>
          <p class="mb-5 text-[14px] text-left break-words">{{ article.data3 }}</p>
        </NuxtLink>
      </li>
    </ul>
    <NuxtLink to="/blog"
              class="mx-auto my-[40px] py-[10px] px-[30px] h-[50px] text-[18px] font-medium cursor-pointer rounded-[25px]
    border border-osn-black-00 transition-shadow">
      {{ $t('article.go_read_more') }}
    </NuxtLink>
  </section>
</template>
<script setup
        lang="ts">
import {getBlogArticle} from "@/api/banner";

const picUrl = import.meta.env.VITE_APP_BASE_IMG_URL

const {data: blogArticleData} = await useAsyncData(
    'blogArticleData',
    () => getBlogArticle(4));

const blogArticle = computed(() => {
  return blogArticleData.value ? blogArticleData.value.list : [];
});

</script>
<style scoped
       lang="scss">

</style>
