import {useSettingStore} from "~/stores/setting";
import {useRoute, useRouter} from "vue-router";
import {computed} from "vue";

export function useGoTo() {
    const settingStore = useSettingStore();
    const router = useRouter();
    const route = useRoute();
    const isMobile = computed(() => settingStore.isMobile);

    function goHome(routeQuery) {
        if (route.path.includes('/space/')) {
            router.push({path: '/'});
        } else {
            router.push({path: settingStore.getHomeRouter, query: routeQuery});
        }


        /*navigateTo({
            path: settingStore.getHomeRouter,
            query: routeQuery
        })*/
    }

    function go(url) {
        router.push({path: url, query: route.query})
    }

    function goWithQuery(url, query) {
        router.push({path: url, query: query})
    }

    function toWebsite(url) {
        window.open(url)
    }

    function goToGoogleMap(address) {
        const googleMapUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.open(googleMapUrl, '_blank');
    }

    function handelLoginBtn() {
        if (isMobile.value) {
            go('/login')
        } else {
            settingStore.updateLoginDialogVisible(true)
        }
    }

    function goToSpacePage(spaceId: string) {
        const route = router.resolve({path: `/space/${spaceId}`})
        window.open(route.href, '_blank')
    }

    function serverGoHome() {
        // navigateTo({path: '/'})
        router.push({path: '/'})
    }

    return {
        goHome,
        go,
        goWithQuery,
        toWebsite,
        goToGoogleMap,
        handelLoginBtn,
        goToSpacePage,
        serverGoHome
    };
}
