<template>
  <div @click="handlePopup"
       class="z-[1000] fixed right-4 pc:top-96 mobile:bottom-4 bg-osn-yellow-00 rounded-full
  w-[96px] h-[96px] flex items-center flex-col justify-center  hover:shadow-lg cursor-pointer
  text-[14px] text-osn-black-00 font-semibold mobile:w-[80px] mobile:h-[80px]">
    <svg-icon name="help_agent"
              :size="'24'"></svg-icon>
    <p>我要找場地</p>
  </div>


  <el-dialog v-model="isPopup"
             width="355"
             class="!rounded-[20px]"
             center>

    <div class="flex flex-col items-center text-osn-black-00">
      <p class="mb-4 text-[20px]">我要找場地</p>
      <a :href="clientLineUrl">
        <img width="240"
             height="240"
             src="https://qr-official.line.me/gs/M_768cpmhz_GW.png?oat_content=qr">
      </a>

      <p class="text-[18px] font-bold">加入官方LINE, 小編一對一服務</p>
      <p class="text-[18px] font-bold">省時又便利</p>
      <p class="mt-[26px] text-[15px]">您也可以填寫需求表單，小編與您聯繫</p>
      <div @click="send"
           class="flex justify-center  cursor-pointer underline text-[15px]">
        <span class="mr-1">場地需求表單</span>
        <svg-icon name="arrow_circle_right"
                  size="24"></svg-icon>
      </div>
    </div>
  </el-dialog>

</template>

<script setup
        lang="ts">
const clientLineUrl = import.meta.env.VITE_APP_BASE_CLIENT_LINE_URL

const isPopup = ref(false)
const handlePopup = () => {
  isPopup.value = true
}
const send = () => {
  window.open('https://docs.google.com/forms/d/12d57U9XO-ZW6cNEVHH8mA308PxziXjAjUmOrro_UriQ/viewform?pli=1&ts=67885e28&pli=1&edit_requested=true')

}

</script>

<style scoped
       lang="scss">

</style>
