import httpRequest from "~/composables/requset";
import type {ApiResponse} from '~/utils/types';
import {hashPassword} from "~/utils/parseTool";

// 會員註冊
export function register(account:string,password:string,name:string): Promise<ApiResponse> {

    const queryData = {
        account: account,
        password: password,
        name: name
    }
    return httpRequest(
        'POST',
        '/api/auth/register',
        queryData
    )
}

// 會員登入
export function login(account:string,password:string): Promise<ApiResponse> {
    const queryData = {
        account: account,
        password: password
    }
    return httpRequest(
        'POST',
        '/api/auth/login',
        queryData
    )
}

// google 登入驗證
export function googleAuthenticate(code,state): Promise<ApiResponse> {
    const queryData = {
        code: code,
        state: state
    }

    return httpRequest(
        'POST',
        '/api/auth/google-authenticate',
        queryData
    )
}

// 會員登出
export function logout(account:string): Promise<ApiResponse> {
    const queryData = {
        account: account
    }
    return httpRequest(
        'POST',
        '/api/auth/logout',
        queryData
    )
}


