<template>
  <div class="pc:mx-auto pc:px-[140px] pc:max-w-[1366px] mobile:px-4">
    <h2 class="text-[24px] font-bold">{{ $t('sub_title.location_search') }}</h2>
    <p class="text-[16px]">{{ $t('sub_title.location_search_description') }}</p>
    <div class="top-card-list">
      <searchCard v-for="item in topCityList"
                  :name="item.name"
                  :img-name="item.imgName"
                  :search-id="item.cityId"
                  :search-store="handleCitySelect"></searchCard>
    </div>
    <div class="unit-list ">
      <div v-for="item in cityList">
        <searchUnitList
            :title="item.name"
            :unit-list="item.info"
            :search-store="handleCitySelect"
            :recommend-space-list="cityRecommendSpaceList"></searchUnitList>
      </div>
    </div>
  </div>
</template>
<script setup>
import searchCard from './SearchCard.vue'
import searchUnitList from "./SearchUnitList.vue";

import {watchEffect, ref} from "vue";
import {useCityStore} from "@/stores/city.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {topCityList, cityLocationClassify,cityRecommendSpaceList} from "@/config/common.js";


const cityStore = useCityStore();
const searchQueryStore = useSearchQueryStore()


const cityList = ref([])


watchEffect(() => {
  const cities = cityStore.getCityList;


  if (cities.length > 0) {
    // 清空 cityList.value 的所有元素
    cityList.value.length = 0;

    // 使用 Object.entries 來迭代 cityLocationClassify
    Object.entries(cityLocationClassify).forEach(([locationName, cityIds]) => {
      // 取得符合條件的城市資訊
      const cityInfoList = cityIds
          .map(cityId => cities.find(cityItem => cityItem.city_id === cityId && cityItem.amount > 0))
          .filter(Boolean); // 過濾掉所有 falsy 值，包括 undefined

      // 如果 cityInfoList 不為空，將其推入 cityList.value
      if (cityInfoList.length > 0) {
        cityList.value.push({name: locationName, info: cityInfoList});
      }
    });
  }


});

function handleCitySelect(cityID) {
  searchQueryStore.setSelectedCityID(cityID)
}
</script>
<style scoped
       lang="scss">

.top-card-list {
  @apply mt-[20px] w-[100%] flex
  mobile:flex-wrap;
  > div {
    @apply basis-[calc(50%-4px)] pc:mr-[8px];
  }

  > div:nth-child(odd) {
    @apply mobile:mr-[8px];
  }
}

.unit-list {
  @apply flex justify-between flex-wrap;
  > div {
    @apply mt-[30px]
    pc:w-[25%]
    mobile:w-full;
  }
}

</style>
