<template>
  <div class="card-wrap"
       :style="backgroundStyle"
       @click="onSearch">
    {{ name }}
  </div>
</template>
<script setup>
import {computed} from "vue"
// import {useGoTo} from "@/composables/useGoTo";
import {useSearchQueryStore} from "@/stores/searchQuery";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import {useImageUrl} from '~/composables/useImageUrl';

const searchQueryStore = useSearchQueryStore()
const {goWithQuery} = useGoTo();
const {handleSearchWithStore} = useSearchWithStore()

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  imgName: {
    type: String,
    default: ''
  },
  searchId: {
    type: Number,
    default: 0
  },
  searchStore: {
    type: Function
  }

})
const getImageUrl = useImageUrl;
const backgroundStyle = computed(() => ({
  backgroundImage: `url(${getImageUrl('background/search', props.imgName + '.png')})`
}));


function onSearch() {
  searchQueryStore.initQueryDate()
  props.searchStore([props.searchId])
  goWithQuery('/search-result', {})
  handleSearchWithStore()
}

</script>
<style scoped
       lang="scss">
.card-wrap {
  @apply font-bold text-[24px] text-center text-osn-white-00 cursor-pointer  bg-contain bg-no-repeat bg-center
  pc:w-[260px] pc:h-[120px] pc:leading-[120px] pc:bg-cover
  mobile:h-[100px] mobile:leading-[100px];
}

</style>
