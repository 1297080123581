<template>
  <div class="space-card-wrap cursor-pointer"
       @click="handleSpaceClick(spaceCardObj.space_id)">
    <NuxtImg format="webp"
             quality="80"
             loading="lazy"
             sizes="100vw md:259px"
             :src="picUrl+spaceCardObj.photo_url"
             class="rounded-t-xl aspect-[1024/683]"
             :alt="spaceCardObj.photo_description?spaceCardObj.photo_description:spaceCardObj.space_name"/>
    <div class="px-[24px] pt-[10px]">
      <h3 class="text-[15px] text-osn-grey-01 truncate">{{ spaceCardObj.brand_name }}</h3>
      <div class="h-[48px] text-[16px] font-bold text-osn-dark-grey-00">{{ spaceCardObj.space_name }}</div>
      <div class="tag-wrap">

        <ul>
          <li class="usage"
              v-for="item in spaceCardObj.categories[usageCategoryId]">{{ item.name }}
          </li>
          <!--          <li class="type"
                        v-for="item in spaceCardObj.categories[typeCategoryId]">{{ item.name }}
                    </li>
                    <li class="tag"
                        v-for="item in spaceCardObj.categories[tagCategoryId]">{{ item.name }}
                    </li>-->
        </ul>
      </div>

      <div class="pc:mt-3 min-h-[20px] inline-flex items-center text-[14px]">
        <svg-icon v-if="spaceCardObj.traffics.length>0"
                  class="mr-[5px]"
                  name="mrt"
                  size="20"></svg-icon>
        <span v-if="spaceCardObj.traffics.length>0">{{
            `${spaceCardObj.traffics[0]?.mrt_station} ${spaceCardObj.traffics[0]?.mrt_exit} 號出口`
          }}</span>
        <span v-if="spaceCardObj.traffics.length>0"
              class="ml-[3px] text-osn-grey-01">{{
            $t('space.walking_time', {t: spaceCardObj.traffics[0]?.walking_time})
          }}</span>
      </div>
      <div class="mt-[8px] px-[4px] pb-[18px] flex justify-between items-center">
        <div class="font-semibold text-[16px]">
          <span class="mr-[5px]">{{ parseFormatCheapestRentPrice(spaceCardObj.rent.rent_groups) }}</span>
          <span class="font-normal text-osn-grey-01 text-[14px]">{{
              `/${parseFormatCheapestRentType(spaceCardObj.rent.rent_groups)}`
            }}</span>
        </div>
        <div class="text-osn-grey-01 text-[14px] inline-flex items-center">
          <svg-icon class="mr-[4px]"
                    name="people"
                    size="16"></svg-icon>
          {{ spaceCardObj.number_of_people_max + '人' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import {parseTrafficsMRT} from '@/utils/parseTool.js'
import {parseFormatCheapestRentPrice, parseFormatCheapestRentType} from "~/utils/newPriceTool.ts";


const picUrl = import.meta.env.VITE_APP_BASE_IMG_URL
const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID
const tagCategoryId = import.meta.env.VITE_APP_BASE_TAG_CATEGORY_ID
const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID
const router = useRouter()

const props = defineProps({
  spaceCardObj: {
    type: Object,
    default: {}
  }
})

function handleTrafficInfo(info) {
  return parseTrafficsMRT(info)

}

function handleSpaceClick(spaceId) {
  const route = router.resolve({path: `/space/${spaceId}`})
  window.open(route.href, '_blank')
}

</script>
<style scoped
       lang="scss">
.space-card-wrap {
  @apply rounded-xl border;

  .tag-wrap {
    @apply mt-1 pc:h-[100px] mobile:h-[85px] text-[13px];
    ul {
      @apply flex flex-wrap;
      li {
        @apply w-auto h-[23px] mr-[2px] mb-[2px] px-[6px] py-[2px] bg-osn-yellow-00 rounded-md;
      }

      li.type {
        @apply bg-osn-yellow-04 text-[11px] py-[4px];
      }

      li.tag {
        @apply bg-osn-yellow-05 text-[11px] py-[4px];
      }

    }
  }
}

</style>
