import httpRequest from "~/composables/requset";
import type {ApiResponse, Article, BlogArticleResponse} from '~/utils/types';

export function banners(): Promise<ApiResponse> {
    return httpRequest(
        'GET',
        '/api/banners/sections/1'
    )
}

export function bannerDetail(id: string): Promise<Article> {
    return httpRequest(
        'GET',
        '/api/banners/' + id
    )
}

export function articleDetail(url: string): Promise<Article> {
    return httpRequest(
        'GET',
        '/api/banners/url/' + url
    )
}


export function getBlogArticle(limit=3,page=1): Promise<BlogArticleResponse> {

    const requestData = {
        "pagination": {
            "dir": 0,
            "limit": limit,
            "page": page,
            "sort": "begin_time"
        },
        "section": 2,
        "show_data1": false,
        "show_data2": false,
        "show_data3": true
    }
    return httpRequest(
        'POST',
        '/api/banners/pagination',
        requestData
    )
}
